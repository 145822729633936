.lds-spinner {
  position: absolute;
  left: 50%;
  top: 30%;
  margin-left: -4em;
}

.navigate-button {
  width: max-content;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 30px;
}
