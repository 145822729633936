.stepper-wrapper {
    width: 100%;
    position: absolute;
    bottom: 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 50%;
    top: 10px;
    left: -10%;
    z-index: 2;
}

.stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 50%;
    top: 10px;
    left: 60%;
    z-index: 2;
}

.stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-bottom: 6px;
}

.stepper-item.active .step-name {
    font-weight: bold;
}

.needle {
    position: relative;
}

.stepper-item .needle:after {
    content: "";
    background : url(/public/needle-in-canvas.png) no-repeat;
    height: 100px;
    width: 100px;
    background-size: 40px;
    position: absolute;
    top: -18px;
    left: -8px;
    z-index: 6;
}

.stepper-item.active .needle:after {
    opacity: 1;
}

.stepper-item .needle:after {
    opacity: 0;
}

.stepper-item .needle:after {
    transition: 0.3s all cubic-bezier(0.25, 0.1, 0.25, 1.0);
}

/*.stepper-item:not(.active) .needle:after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    z-index: 6;*/
/*}*/

.stepper-item.disabled {
    cursor: not-allowed;
}

.stepper-item.disabled .step-counter {
    background-color: rgba(108, 117, 125, 0.2);
    font-weight: 60;
}

.stepper-item.disabled .step-name {
    font-weight: 60;
}

.stepper-item.completed.active .step-counter {
    background-color: #0db1f0;
}

.stepper-item .step-counter {
    background-color: #0dcaf061;
} 

.stepper-item:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.stepper-item.completed::after {
    flex: 1 0 32px;
    min-width: 1px;
    min-height: 1px;
    margin: auto;
    background-color: rgba(0,0,0,.12);
}

.stepper-item:first-child::before {
    content: none;
}
.stepper-item:last-child::after {
    content: none;
}