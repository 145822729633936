.ReactCrop {
  border: dotted #0000002e;
  border-radius: 1%;
  min-height: 70vh;
}

.ReactCrop__image {
  min-height: 70vh;
}

.image-crop-wrapper {
  width: 100%;
  height: 100%;
}