.arrow-right {
  cursor: pointer;
  position: absolute;
  right: 95px;
  //top: 200px;
  top: 35%;
  display: block;
  //margin: 100px 0 0 100px;
  width: 100px;
  height: 100px;
  //border: solid 6px #999;
  border-radius: 100%;
  z-index: 1;
  transition: all .2s linear;

  &:before, &:after {
    content: "";
    position: absolute;
    width: 35%;
    height: 10%;
    top: 41%;
    left: 55%;
    background: #999;
    z-index: 2;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all .2s linear;
  }

  &:after {
    z-index: 3;
    top: 59%;
    left: 55%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    border: solid 8px #777;

    &:after, &:before {
      background: #777;
    }
  }

  &:active {
    border: solid 8px #111;

    &:after, &:before {
      background: #111;
    }
  }
}

.arrow-left {
  cursor: pointer;
  position: absolute;
  left: 95px;
  //top: 200px;
  top: 35%;
  display: block;
  //margin: 100px 0 0 100px;
  width: 100px;
  height: 100px;
  //border: solid 6px #999;
  border-radius: 100%;
  z-index: 1;
  transition: all .2s linear;

  &:before, &:after {
    content: "";
    position: absolute;
    width: 35%;
    height: 10%;
    top: 41%;
    left: 45%;
    background: #999;
    z-index: 2;
    transform: translate(-50%, -50%) rotate(135deg);
    transition: all .2s linear;
  }

  &:after {
    z-index: 3;
    top: 59%;
    left: 45%;
    transform: translate(-50%, -50%) rotate(-135deg);
  }

  &:hover {
    border: solid 8px #777;

    &:after, &:before {
      background: #777;
    }
  }

  &:active {
    border: solid 8px #111;

    &:after, &:before {
      background: #111;
    }
  }
}