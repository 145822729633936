.schema-canvas {
  max-height: inherit;
  max-width: inherit;
}

.schema-canvas-wrapper {
  max-width: 80%;
  max-height: 75vh;
  min-height: 75vh;
  margin-left: 300px;
  border: dotted rgba(0, 0, 0, 0.1803921569);
  box-sizing: content-box;
}

.all-colors {
  overflow: scroll;
  max-height: 75vh;
  position: absolute;
  top: 0;
  max-width: 30vh;
}

.only-symbols-check {
  display: table;
  margin-left: 20px;
  margin-top: 10px;

  > label {
    padding-left: 10px;
  }
}
