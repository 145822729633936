.drop-zone {
  position: absolute;
  top: 25%;
  left: 25%;
  height: 200px;
  border-color: #bbbbbb;
  background-color: #f5f4f4;
  color: #8f8f8f;
  justify-content: center;
  align-content: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  outline: none;
  transition: border .24s ease-in-out;
  margin: auto;
  width: 50%;
}

.load-description {
  position: absolute;
  top: 68px;
}

.load-description:after {
  content: "";
  background: url("../../public/upload.png") no-repeat;
  width: 100px;
  height: 100px;
  position: absolute;
  background-size: 40px;
  left: 48%;
  top: 148%;
  opacity: 0.4;
}
