.setup-colors-canvas {
  max-height: inherit;
  max-width: inherit;
}

.canvas-wrapper {
  min-width: 75vh;
  min-height: 75vh;
  max-height: 75vh;
  padding: 0 !important;
  border: dotted rgba(0, 0, 0, 0.1803921569);
  box-sizing: content-box;
}

.lds-spinner {
  position: absolute;
  left: 50%;
  top: 30%;
  margin-left: -4em;
}

.zoom-button {
  color: gray;
  font-size: 30px;
}

.arrow {
  margin: auto;
  width: 2px;
  height: 75px;
  background-color: #100e0e4a;
  transform: rotate(
                  90deg
  );
  position: relative;
}

.arrow > label {
  transform: rotate(
                  -90deg
  );
  position: absolute;
  top: 30%;
  left: -40px;
  background: white;
}

.arrow:after {
  content: '';
  position: absolute;
  top: -3px;
  left: 0;
  border-style: solid;
  transform: translate(-40%, -50%);
  border-width: 0 7px 6px 7px;
  border-color: transparent transparent #100e0e4a transparent;
}

.arrow:before {
  content: '';
  position: absolute;
  bottom: -6px;
  left: -6px;
  border-style: solid;
  transform: rotate(180deg);
  border-width: 0 7px 6px 7px;
  border-color: transparent transparent #100e0e4a transparent;
}

.navigate-button {
  width: max-content;
}

.imitate-canvas {
  padding-top: 30px;
}