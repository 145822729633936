
.download-schema {
  margin-top: 10px;
}

.spinner-wrapper {
  z-index: 10;
  width: 100vw;
  position: absolute;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
}